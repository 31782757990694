<template>
  <v-card :loading="loading">
    <v-toolbar>
      <v-toolbar-title>{{ $i18n.translate("Sale") }} - {{ form.saleKey }} </v-toolbar-title>
      <v-spacer></v-spacer>

      <v-tooltip
        bottom
        v-if="$privilege.hasPrivilege('SALE_UPDATE') && !isEditing && form.id && form.eligibleForPayoutGeneration"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="mr-2" @click="onGeneratePayouts(false)" v-bind="attrs" :disabled="!valid" v-on="on">
            Generate Payouts
          </v-btn>
        </template>
        <span>{{ $i18n.translate("Generate Payouts") }}</span>
      </v-tooltip>

      <v-tooltip
        bottom
        v-if="$privilege.hasPrivilege('SALE_UPDATE') && !isEditing && form.id && form.eligibleForPayoutGeneration"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="mr-2" @click="onGeneratePayouts(true)" v-bind="attrs" :disabled="!valid" v-on="on">
            Regenerate Payouts
          </v-btn>
        </template>
        <span>{{ $i18n.translate("Regenerate Payouts") }}</span>
      </v-tooltip>

      <v-tooltip bottom v-if="$privilege.hasPrivilege('SALE_UPDATE') && !isEditing && form.id">
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="mr-2" @click="onPayPayouts" v-bind="attrs" :disabled="!valid" v-on="on">
            Pay Payouts
          </v-btn>
        </template>
        <span>{{ $i18n.translate("Pay Payouts") }}</span>
      </v-tooltip>

      <v-tooltip bottom v-if="$privilege.hasPrivilege('SALE_UPDATE') && isEditing">
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="mr-2" fab small @click="onSubmit" v-bind="attrs" :disabled="!valid" v-on="on">
            <v-icon>mdi-content-save</v-icon>
          </v-btn>
        </template>
        <span>{{ $i18n.translate("Save") }}</span>
      </v-tooltip>

      <v-tooltip bottom v-if="$privilege.hasPrivilege('SALE_UPDATE') && !isNew">
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="mr-2" fab small @click="onEdit" v-bind="attrs" v-on="on">
            <v-icon v-if="isEditing">mdi-close</v-icon>
            <v-icon v-else>mdi-pencil</v-icon>
          </v-btn>
        </template>
        <span v-if="isEditing">{{ $i18n.translate("Cancel") }}</span>
        <span v-else>{{ $i18n.translate("Edit") }}</span>
      </v-tooltip>

      <template v-slot:extension>
        <v-tabs v-model="tab">
          <v-tab>{{ $i18n.translate("Details") }}</v-tab>
          <v-tab>{{ $i18n.translate("Custom Fields") }}</v-tab>
          <v-tab>{{ $i18n.translate("Sale Products") }}</v-tab>
          <v-tab>{{ $i18n.translate("Uploads") }}</v-tab>
          <v-tab>{{ $i18n.translate("Payouts") }}</v-tab>
          <v-tab>{{ $i18n.translate("Timeline") }}</v-tab>
        </v-tabs>
      </template>
    </v-toolbar>
    <v-card-text>
      <ApiError :errors="errors"></ApiError>
      <div class="text-center" v-if="loading">
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </div>
      <v-form :value="valid" @submit.prevent="onSubmit" v-if="!loading" v-model="valid">
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-container>
              <v-row>
                <v-col cols="8">
                  <KeyField
                    name="saleKey"
                    label="Key"
                    hint="Uniquely identifies a sale in this program"
                    :rules="rules.saleKey"
                    v-model="form.saleKey"
                    :disabled="!isEditing"
                    :error-messages="$error.getValidationError(errors, 'saleKey')"
                    @input="$error.clearValidationError(errors, 'saleKey')"
                    class="required"
                  ></KeyField>
                  <SaleTypeField
                    v-model="saleType"
                    :label="$i18n.translate('Sale Type')"
                    :disabled="!isEditing"
                  ></SaleTypeField>
                  <DateTimePickerField
                    v-model="form.saleDate"
                    :disabled="!isEditing"
                    :label="$i18n.translate('Sale Date')"
                    defaultTime="00:00"
                  ></DateTimePickerField>
                  <v-text-field
                    label="Source"
                    name="source"
                    v-model="form.source"
                    :disabled="!isEditing"
                    :error-messages="$error.getValidationError(errors, 'source')"
                    @input="$error.clearValidationError(errors, 'source')"
                  ></v-text-field>
                  <v-text-field
                    label="Batch Number"
                    name="batchNumber"
                    v-model="form.batchNumber"
                    :disabled="!isEditing"
                    :error-messages="$error.getValidationError(errors, 'batchNumber')"
                    @input="$error.clearValidationError(errors, 'batchNumber')"
                  ></v-text-field>
                  <v-text-field
                    v-if="saleType && saleType.allowSaleAmount"
                    type="number"
                    label="Sale Amount"
                    name="saleAmount"
                    v-model="form.saleAmount"
                    :disabled="!isEditing"
                    :error-messages="$error.getValidationError(errors, 'saleAmount')"
                    @input="$error.clearValidationError(errors, 'saleAmount')"
                  ></v-text-field>
                  <ParticipantField
                    showKey
                    v-model="form.participant"
                    :label="$i18n.translate('Participant')"
                    :disabled="!isEditing"
                    :rules="rules.participant"
                  ></ParticipantField>
                  <OrganizationField
                    v-model="form.organization"
                    :label="$i18n.translate('Organization')"
                    :disabled="!isEditing"
                    :rules="rules.organization"
                    showKey
                  ></OrganizationField>
                  <v-text-field
                    label="Customer Company"
                    name="companyName"
                    v-model="form.companyName"
                    :disabled="!isEditing"
                    :error-messages="$error.getValidationError(errors, 'companyName')"
                    @input="$error.clearValidationError(errors, 'companyName')"
                  ></v-text-field>
                  <v-row class="mb-0 mt-0">
                    <v-col cols="4" class="pb-0 pt-0">
                      <v-text-field
                        label="Customer First Name"
                        name="firstName"
                        v-model="form.firstName"
                        :disabled="!isEditing"
                        :error-messages="$error.getValidationError(errors, 'firstName')"
                        @input="$error.clearValidationError(errors, 'firstName')"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="4" class="pb-0 pt-0">
                      <v-text-field
                        label="Customer Middle Name"
                        name="middleName"
                        v-model="form.middleName"
                        :disabled="!isEditing"
                        :error-messages="$error.getValidationError(errors, 'middleName')"
                        @input="$error.clearValidationError(errors, 'middleName')"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="4" class="pb-0 pt-0">
                      <v-text-field
                        label="Customer Last Name"
                        name="lastName"
                        v-model="form.lastName"
                        :disabled="!isEditing"
                        :error-messages="$error.getValidationError(errors, 'lastName')"
                        @input="$error.clearValidationError(errors, 'lastName')"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row class="mb-0 mt-0">
                    <v-col cols="6" class="pb-0 pt-0">
                      <v-text-field
                        label="Customer Address 1"
                        name="address1"
                        v-model="form.address.address1"
                        :disabled="!isEditing"
                        :error-messages="$error.getValidationError(errors, 'address1')"
                        @input="$error.clearValidationError(errors, 'address1')"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6" class="pb-0 pt-0">
                      <v-text-field
                        label="Customer Address 2"
                        name="address1"
                        v-model="form.address.address2"
                        :disabled="!isEditing"
                        :error-messages="$error.getValidationError(errors, 'address2')"
                        @input="$error.clearValidationError(errors, 'address2')"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="mb-0 mt-0">
                    <v-col cols="3" class="pb-0 pt-0">
                      <v-text-field
                        name="city"
                        :label="$i18n.translate('Customer City')"
                        id="city"
                        v-model="form.address.city"
                        :rules="rules.city"
                        :disabled="!isEditing"
                        :error-messages="$error.getValidationError(errors, 'city')"
                        @input="$error.clearValidationError(errors, 'city')"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="3" class="pb-0 pt-0">
                      <CountryField
                        v-model="form.address.country"
                        name="country"
                        :label="$i18n.translate('Customer Country')"
                        id="country"
                        :rules="rules.country"
                        :disabled="!isEditing"
                        :error-messages="$error.getValidationError(errors, 'country')"
                        @input="$error.clearValidationError(errors, 'country')"
                        :program="selectedProgram"
                        class="required"
                      ></CountryField>
                    </v-col>
                    <v-col cols="3" class="pb-0 pt-0">
                      <RegionField
                        :country="form.address.country"
                        :disabled="!isEditing"
                        name="region"
                        v-model="form.address.region"
                        id="region"
                        :rules="rules.region"
                        :error-messages="$error.getValidationError(errors, 'region')"
                        @input="$error.clearValidationError(errors, 'region')"
                      ></RegionField>
                    </v-col>
                    <v-col cols="3" class="pb-0 pt-0">
                      <PostalCodeField
                        name="postalCode"
                        id="postalCode"
                        v-model="form.address.postalCode"
                        :rules="rules.postalCode"
                        :disabled="!isEditing"
                        :error-messages="$error.getValidationError(errors, 'postalCode')"
                        @input="$error.clearValidationError(errors, 'postalCode')"
                        :country="form.address.country"
                      ></PostalCodeField>
                    </v-col>
                  </v-row>

                  <v-switch
                    dense
                    label="Eligible for Payout Generation?"
                    v-model="form.eligibleForPayoutGeneration"
                    :disabled="!isEditing"
                  ></v-switch>

                  <DateTimePickerField
                    v-model="form.effectiveDate"
                    :disabled="!isEditing"
                    :label="$i18n.translate('Effective Date')"
                    defaultTime="00:00"
                  ></DateTimePickerField>
                  <DateTimePickerField
                    v-model="form.expirationDate"
                    :disabled="!isEditing"
                    :label="$i18n.translate('Expiration Date')"
                    defaultTime="23:59"
                  ></DateTimePickerField>
                </v-col>
              </v-row>
            </v-container>
          </v-tab-item>
          <v-tab-item>
            <v-container v-if="saleType">
              <v-row>
                <v-col cols="6">
                  <CustomField
                    v-model="form.customFieldValue1"
                    :disabled="!isEditing"
                    v-if="selectedProgram.saleCustomFields && selectedProgram.saleCustomFields.length >= 1"
                    :customField="selectedProgram.saleCustomFields[0]"
                  />

                  <CustomField
                    v-model="form.customFieldValue2"
                    :disabled="!isEditing"
                    v-if="selectedProgram.saleCustomFields && selectedProgram.saleCustomFields.length >= 2"
                    :customField="selectedProgram.saleCustomFields[1]"
                  />

                  <CustomField
                    v-model="form.customFieldValue3"
                    :disabled="!isEditing"
                    v-if="selectedProgram.saleCustomFields && selectedProgram.saleCustomFields.length >= 3"
                    :customField="selectedProgram.saleCustomFields[2]"
                  />

                  <CustomField
                    v-model="form.customFieldValue4"
                    :disabled="!isEditing"
                    v-if="selectedProgram.saleCustomFields && selectedProgram.saleCustomFields.length >= 4"
                    :customField="selectedProgram.saleCustomFields[3]"
                  />

                  <CustomField
                    v-model="form.customFieldValue5"
                    :disabled="!isEditing"
                    v-if="selectedProgram.saleCustomFields && selectedProgram.saleCustomFields.length >= 5"
                    :customField="selectedProgram.saleCustomFields[4]"
                  />

                  <CustomField
                    v-model="form.customFieldValue6"
                    :disabled="!isEditing"
                    v-if="selectedProgram.saleCustomFields && selectedProgram.saleCustomFields.length >= 6"
                    :customField="selectedProgram.saleCustomFields[5]"
                  />

                  <CustomField
                    v-model="form.customFieldValue7"
                    :disabled="!isEditing"
                    v-if="selectedProgram.saleCustomFields && selectedProgram.saleCustomFields.length >= 7"
                    :customField="selectedProgram.saleCustomFields[6]"
                  />

                  <CustomField
                    v-model="form.customFieldValue8"
                    :disabled="!isEditing"
                    v-if="selectedProgram.saleCustomFields && selectedProgram.saleCustomFields.length >= 8"
                    :customField="selectedProgram.saleCustomFields[7]"
                  />

                  <CustomField
                    v-model="form.customFieldValue9"
                    :disabled="!isEditing"
                    v-if="selectedProgram.saleCustomFields && selectedProgram.saleCustomFields.length >= 9"
                    :customField="selectedProgram.saleCustomFields[8]"
                  />

                  <CustomField
                    v-model="form.customFieldValue10"
                    :disabled="!isEditing"
                    v-if="selectedProgram.saleCustomFields && selectedProgram.saleCustomFields.length >= 10"
                    :customField="selectedProgram.saleCustomFields[9]"
                  />

                  <CustomField
                    v-model="form.customFieldValue11"
                    :disabled="!isEditing"
                    v-if="selectedProgram.saleCustomFields && selectedProgram.saleCustomFields.length >= 11"
                    :customField="selectedProgram.saleCustomFields[10]"
                  />

                  <CustomField
                    v-model="form.customFieldValue12"
                    :disabled="!isEditing"
                    v-if="selectedProgram.saleCustomFields && selectedProgram.saleCustomFields.length >= 12"
                    :customField="selectedProgram.saleCustomFields[11]"
                  />

                  <CustomField
                    v-model="form.customFieldValue13"
                    :disabled="!isEditing"
                    v-if="selectedProgram.saleCustomFields && selectedProgram.saleCustomFields.length >= 13"
                    :customField="selectedProgram.saleCustomFields[12]"
                  />

                  <CustomField
                    v-model="form.customFieldValue14"
                    :disabled="!isEditing"
                    v-if="selectedProgram.saleCustomFields && selectedProgram.saleCustomFields.length >= 14"
                    :customField="selectedProgram.saleCustomFields[13]"
                  />

                  <CustomField
                    v-model="form.customFieldValue15"
                    :disabled="!isEditing"
                    v-if="selectedProgram.saleCustomFields && selectedProgram.saleCustomFields.length >= 15"
                    :customField="selectedProgram.saleCustomFields[14]"
                  />
                </v-col>
                <v-col cols="6">
                  <h2 class="mb-2">Product Custom Fields</h2>
                  <ol>
                    <li>
                      Configure each custom field at the Program Group level.
                    </li>
                  </ol>
                </v-col>
              </v-row>
            </v-container>
          </v-tab-item>
          <v-tab-item>
            <SaleProductTable
              v-if="form.id && saleType"
              :sale="form"
              :saleType="saleType"
              :disabled="!isEditing"
              :isEditing="isEditing"
            >
            </SaleProductTable>
          </v-tab-item>
          <v-tab-item>
            <v-row>
              <v-col cols="12">
                <UploadTable
                  v-model="saleUploads"
                  :allowRemoval="true"
                  :disabled="!isEditing"
                  @input="bindUploadsIfClosed($event)"
                ></UploadTable>
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item>
            <Payouts v-if="form.id" :sale="form" nested> </Payouts>
          </v-tab-item>
          <v-tab-item>
            <ActivityTimeline type="sale" :id="form.id"></ActivityTimeline>
          </v-tab-item>
        </v-tabs-items>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import ApiError from "../../display/ApiError.vue";
import CountryField from "../../fields/CountryField.vue";
import CustomField from "../../fields/CustomField.vue";
import DateTimePickerField from "../../fields/DateTimePickerField.vue";
import KeyField from "../../fields/KeyField.vue";
import OrganizationField from "../../fields/OrganizationField.vue";
import ParticipantField from "../../fields/ParticipantField.vue";
import PostalCodeField from "../../fields/PostalCodeField.vue";
import RegionField from "../../fields/RegionField.vue";
import SaleTypeField from "../../fields/SaleTypeField.vue";
import ActivityTimeline from "../../tables/ActivityTimeline.vue";
import SaleProductTable from "../../tables/SaleProductTable.vue";
import UploadTable from "../../tables/UploadTable.vue";
import Payouts from "./Payouts.vue";

export default {
  name: "Sale",
  metaInfo: {
    title: "Sale"
  },
  components: {
    ApiError,
    KeyField,
    SaleTypeField,
    DateTimePickerField,
    ParticipantField,
    OrganizationField,
    CountryField,
    RegionField,
    PostalCodeField,
    CustomField,
    SaleProductTable,
    UploadTable,
    Payouts,
    ActivityTimeline
  },
  data() {
    return {
      loading: false,
      tab: null,
      valid: true,
      isNew: false,
      isEditing: false,
      form: {
        address: {},
        effectiveDate: null,
        expirationDate: null
      },
      errors: {},
      rules: {
        participant: [v => !v || (!!v && v.id > 0) || "Invalid participant"],
        organization: [v => !v || (!!v && v.id > 0) || "Invalid organization"],
        saleKey: [v => !!v || "Key is required"]
      },

      saleType: undefined,
      participant: {
        participantType: {
          nameAndKey: ""
        }
      },
      saleUploads: {
        existing: [],
        deleted: []
      },
      uploadedUser: null
    };
  },
  methods: {
    onSubmit() {
      if (this.isNew) {
        return;
      }
      this.loading = true;
      this.errors = {};

      let postForm = { sale: Object.assign({}, this.form) };
      postForm.sale.saleType = { id: this.saleType.id };
      postForm.sale.participant = postForm.sale.participant ? { id: postForm.sale.participant.id } : null;
      postForm.sale.organization = postForm.sale.organization ? { id: postForm.sale.organization.id } : null;
      postForm.sale.uploads = this.saleUploads.existing;

      return this.$api
        .post("/api/sales/" + this.$route.params.id + "/update", postForm)
        .then(() => {
          this.fetchData();
        })
        .catch(error => {
          this.errors = this.$api.getErrorsFromResponse(error);
        })
        .finally(() => {
          this.isEditing = false;
          this.loading = false;
        });
    },
    onEdit() {
      this.toggleEditing(!this.isEditing);
      if (!this.isEditing) {
        this.fetchData();
      }
    },
    onGeneratePayouts(force) {
      this.loading = true;
      this.errors = {};
      return this.$api
        .post("/api/sales/" + this.$route.params.id + "/generatePayouts" + (force ? "/force" : ""))
        .then(() => {})
        .catch(error => {
          this.errors = this.$api.getErrorsFromResponse(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onPayPayouts() {
      this.loading = true;
      this.errors = {};
      return this.$api
        .post("/api/sales/" + this.$route.params.id + "/payPayouts")
        .then(() => {})
        .catch(error => {
          this.errors = this.$api.getErrorsFromResponse(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    fetchData() {
      if (!this.isNew) {
        this.loading = true;
        return this.$api.get("/api/sales/" + this.$route.params.id).then(({ data }) => {
          this.form = data;
          return Promise.all([
            this.$api
              .getRelatedObject("participant", data)
              .then(({ data }) => {
                this.form.participant = data;
              })
              .catch(() => {
                console.log("No " + this.$i18n.translate("Participant") + " found");
              }),
            this.$api
              .getRelatedObject("organization", data)
              .then(({ data }) => {
                this.form.organization = data;
              })
              .catch(() => {
                console.log("No " + this.$i18n.translate("Organization") + " found");
              }),
            this.$api.getRelatedObject("uploads", data).then(({ data }) => {
              this.saleUploads.existing = data._embedded.uploads;
            }),
            this.$api.getRelatedObject("saleType", data).then(({ data }) => {
              this.saleType = data;
            })
          ]).finally(() => {
            this.loading = false;
            this.breadcrumb();
          });
        });
      } else {
        this.loading = false;
      }
    },

    toggleEditing(editing) {
      this.isEditing = editing;
    },
    breadcrumb() {
      if (this.isNew) {
        this.$store.dispatch("setBreadcrumb", [
          {
            text: this.$i18n.translate("Dashboard"),
            to: { name: "dashboard" },
            exact: true
          },
          {
            text: this.$i18n.translate("Clients"),
            to: { name: "clients" },
            exact: true
          },
          {
            text: this.selectedClient.name,
            to: {
              name: "client",
              params: { id: this.selectedClient.id },
              exact: true
            }
          },
          {
            text: this.$i18n.translate("Programs"),
            to: { name: "programs" },
            exact: true
          },
          {
            text: this.selectedProgram.name,
            to: {
              name: "program",
              params: { id: this.selectedProgram.id },
              exact: true
            }
          },
          {
            text: "Sales",
            to: { name: "sales" },
            exact: true
          },
          { text: "New Sale" }
        ]);
      } else {
        this.$store.dispatch("setBreadcrumb", [
          {
            text: this.$i18n.translate("Dashboard"),
            to: { name: "dashboard" },
            exact: true
          },
          {
            text: this.$i18n.translate("Clients"),
            to: { name: "clients" },
            exact: true
          },
          {
            text: this.selectedClient.name,
            to: {
              name: "client",
              params: { id: this.selectedClient.id },
              exact: true
            }
          },
          {
            text: this.$i18n.translate("Programs"),
            to: { name: "programs" },
            exact: true
          },
          {
            text: this.selectedProgram.name,
            to: {
              name: "program",
              params: { id: this.selectedProgram.id },
              exact: true
            }
          },
          {
            text: "Sales",
            to: { name: "sales" },
            exact: true
          },
          { text: this.form.saleKey }
        ]);
      }
    },
    onSaleProductChange() {
      this.onEdit();
    }
  },
  computed: {
    ...mapGetters(["selectedClient", "selectedProgram"])
  },
  mounted() {
    if (this.$route.params.id == 0) {
      this.isNew = true;
      this.isEditing = true;
      this.toggleEditing(true);
      this.breadcrumb();
    }

    this.fetchData();
  }
};
</script>
