<template>
  <v-select
    :value="value"
    @input="onInput"
    v-bind="$attrs"
    v-on="$listeners"
    :items="saleTypes"
    item-value="id"
    item-text="name"
    clearable
    return-object
  >
  </v-select>
</template>

<script>
import ApiService from "../../services/api.service";

export default {
  name: "SaleTypeField",
  props: {
    value: Object,
    status: { type: String, required: false }
  },
  data: () => ({
    saleTypes: []
  }),
  methods: {
    onInput(obj) {
      this.$emit("input", obj);
    }
  },
  mounted() {
    ApiService.post("/api/saleTypes/search?size=100", { isEffective: this.status }).then(({ data }) => {
      this.saleTypes = data.content;
    });
  }
};
</script>
