<template>
  <v-card>
    <v-toolbar>
      <v-toolbar-title>Payouts</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-form @submit.stop.prevent="onSubmit">
        <v-text-field
          class="mr-6"
          v-model="search"
          :label="$i18n.translate('Filter') + ' ' + $i18n.translate('Payouts')"
          flat
          solo-inverted
          hide-details
          clearable
          clear-icon="mdi-close-circle-outline"
          @click:clear="onClear"
        ></v-text-field>
      </v-form>
      <v-menu offset-y :close-on-content-click="false">
        <template v-slot:activator="{ on: onMenu }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on: onTooltip }">
              <v-btn class="mr-2" icon v-on="{ ...onMenu, ...onTooltip }">
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
            </template>
            <span>{{ $i18n.translate("Advanced Search") }}</span>
          </v-tooltip>
        </template>
        <v-list>
          <v-list-item-group color="primary">
            <v-subheader>{{ $i18n.translate("Advanced Search") }}</v-subheader>
            <v-list-item selectable>
              <v-list-item-content>
                <v-select
                  dense
                  :items="effectiveItems"
                  v-model="filters.isEffective"
                  :label="$i18n.translate('Active') + ' / ' + $i18n.translate('Inactive')"
                  clearable
                  @input="onSubmit"
                ></v-select>
              </v-list-item-content>
            </v-list-item>
            <v-list-item selectable>
              <v-list-item-content>
                <v-select
                  dense
                  :items="[
                    { text: 'Yes', value: true },
                    { text: 'No', value: false }
                  ]"
                  v-model="filters.communicatedToClient"
                  :label="$i18n.translate('Communicated to ') + $i18n.translate('Client') + '?'"
                  clearable
                  @input="onSubmit"
                ></v-select>
              </v-list-item-content>
            </v-list-item>
            <v-list-item selectable>
              <v-list-item-content>
                <DateTimePickerField
                  dense
                  v-model="filters.paidAfter"
                  :label="$i18n.translate('Paid After Date')"
                  defaultTime="00:00"
                  @input="onSubmit"
                ></DateTimePickerField>
              </v-list-item-content>
            </v-list-item>
            <v-list-item selectable>
              <v-list-item-content>
                <DateTimePickerField
                  dense
                  v-model="filters.paidBefore"
                  :label="$i18n.translate('Paid Before Date')"
                  defaultTime="00:00"
                  @input="onSubmit"
                ></DateTimePickerField>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
      <v-btn v-if="$privilege.hasPrivilege('PAYOUT_CREATE')" fab small :to="{ name: 'payout', params: { id: 0 } }">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text>
      <v-data-table
        :items="items"
        :headers="headers"
        :server-items-length="total"
        :options.sync="options"
        :loading="loading"
      >
        <template v-slot:item.payoutKey="{ item }">
          <router-link :to="{ name: 'payout', params: { id: item?.id } }">
            {{ item.payoutKey }}
          </router-link>
        </template>
        <template v-slot:item.payoutAmount="{ item }">
          <span v-if="item.payoutAmount">
            {{ item.payoutAmount | toNumber(2) }}
            {{ item.currency ? item.currency.name : "" }}
          </span>
        </template>

        <template v-slot:item.promotion.name="{ item }">
          <router-link
            v-if="$privilege.hasPrivilege('PROMOTION_READ')"
            :to="{ name: 'promotion', params: { id: item?.promotion?.id } }"
            >{{ item?.promotion?.promotionKey }} </router-link
          ><span v-else>{{ item?.promotion?.promotionKey }}</span
          ><br />
          {{ item?.promotion?.name }}
        </template>

        <template v-slot:item.participant="{ item }">
          <span v-if="item.participant && item.participant.id">
            <router-link
              v-if="$privilege.hasPrivilege('PARTICIPANT_READ')"
              :to="{ name: 'participant', params: { id: item.participant.id } }"
              >{{ item.participant.participantKey }} </router-link
            ><span v-else>{{ item.participant.participantKey }}</span
            ><br />
            {{ item.participant.fullName }}
          </span>
        </template>

        <template v-slot:item.organization="{ item }">
          <span v-if="item.organization && item.organization.id">
            <router-link
              v-if="$privilege.hasPrivilege('ORGANIZATION_READ')"
              :to="{ name: 'organization', params: { id: item.organization.id } }"
              >{{ item.organization.organizationKey }}</router-link
            ><span v-else>{{ item.organization.organizationKey }}</span
            ><br />
            {{ item.organization.name }}
          </span>
        </template>

        <template v-slot:item.approval="{ item }">
          <span v-if="item.approval">
            <router-link
              v-if="$privilege.hasPrivilege('APPROVAL_READ')"
              :to="{ name: 'approval', params: { id: item.approval.id } }"
              >{{ item.approval.approvalKey }}</router-link
            ><span v-else>{{ item.approval.approvalKey }}</span
            ><br />
            {{ item.approval.approvalStage.name }}
          </span>
          <span v-else>
            No
          </span>
        </template>

        <template v-slot:item.product="{ item }">
          <span v-if="item.claimProduct">
            <router-link
              v-if="$privilege.hasPrivilege('PRODUCT_READ')"
              :to="{ name: 'product', params: { id: item.claimProduct.product.id } }"
              >{{ item.claimProduct.product.productKey }}</router-link
            ><span v-else>{{ item.claimProduct.product.productKey }}</span
            ><br />

            {{ item.claimProduct.product.name }}<br />
          </span>
          <span v-else-if="item.saleProduct">
            <router-link
              v-if="$privilege.hasPrivilege('PRODUCT_READ')"
              :to="{ name: 'product', params: { id: item.saleProduct.product.id } }"
              >{{ item.saleProduct.product.productKey }}</router-link
            ><span v-else>{{ item.saleProduct.product.productKey }}</span
            ><br />
            {{ item.saleProduct.product.name }}<br />
          </span>
        </template>

        <template v-slot:item.bankTransaction="{ item }">
          <span v-if="item.bankTransaction">
            <router-link
              v-if="$privilege.hasPrivilege('BANK_TRANSACTION_READ')"
              :to="{ name: 'bankTransaction', params: { id: item.bankTransaction.id } }"
              >{{ item.bankTransaction.bankTransactionKey }}</router-link
            ><span v-else>{{ item.bankTransaction.bankTransactionKey }}</span>
            <br />
          </span>
          <span v-else> Not paid yet<br /> </span>
          <span v-if="item.awardVehicleName">
            {{ item.awardVehicleName }}
            <br />
          </span>
          <span v-if="item.awardVehicleDetermination">
            {{ item.awardVehicleDetermination.description }}
            <br />
          </span>
        </template>

        <template v-slot:item.updatedDate="{ item }">
          {{ item.updatedDate | formatDateFromNow }}
        </template>

        <template v-slot:item.actions="{ item }">
          <v-icon v-if="!disabled && !item.paid" @click="onDeleteConfirm(item)">
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
      <v-dialog v-if="deleteConfirmItem" v-model="deleteConfirm" max-width="650">
        <v-card>
          <v-card-title>
            Delete this payout?
          </v-card-title>
          <v-card-text>
            <p class="red--text" v-if="deleteConfirmItem.approval && !deleteConfirmItem.approval.closed">
              This payout is currently in an approval process. Close approval before deleting.
            </p>
            <b>Key</b>: {{ deleteConfirmItem.payoutKey }}<br />
            <b>Promotion</b>:
            {{
              deleteConfirmItem.promotion
                ? `${deleteConfirmItem.promotion.promotionKey} - ${deleteConfirmItem.promotion.name}`
                : ""
            }}<br />
            <b>Participant</b>:
            {{
              deleteConfirmItem.participant
                ? `${deleteConfirmItem.participant.participantKey} - ${deleteConfirmItem.participant.fullName}`
                : ""
            }}<br />
            <b>Product</b>:
            {{ deleteConfirmItem.claimProduct ? deleteConfirmItem.claimProduct.product.productKey : "" }}
            {{ deleteConfirmItem.saleProduct ? deleteConfirmItem.saleProduct.product.productKey : "" }}
            <br />
            <b>Amount</b>: {{ deleteConfirmItem.payoutAmount | toNumber(2) }}
            {{ deleteConfirmItem.currency ? deleteConfirmItem.currency.name : "" }}<br />
            <b>Award Vehicle</b>: {{ deleteConfirmItem.awardVehicleName ? deleteConfirmItem.awardVehicleName : ""
            }}<br />
            <b>Under Approval</b>:
            {{ deleteConfirmItem.approval && !deleteConfirmItem.approval.closed ? "Yes" : "No" }}
            {{ deleteConfirmItem.approval ? " - " + deleteConfirmItem.approval.approvalKey : "" }}
            {{ deleteConfirmItem.approval ? " - " + deleteConfirmItem.approval.approvalStage.name : "" }}
          </v-card-text>

          <v-card-actions
            ><v-spacer></v-spacer
            ><v-btn
              class="primary"
              :disabled="deleteConfirmItem.approval && !deleteConfirmItem.approval.closed"
              @click="onDelete(deleteConfirmItem)"
              >Yes</v-btn
            ><v-btn @click="onDeleteCancel()">No</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import DateTimePickerField from "../../fields/DateTimePickerField.vue";

export default {
  components: { DateTimePickerField },
  name: "Payouts",
  metaInfo: {
    title: "Payouts"
  },
  props: {
    sale: Object,
    participant: Object,
    organization: Object,
    nested: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false }
  },
  data() {
    return {
      loading: false,
      items: [],
      nameFilter: "",
      search: "",
      filters: {
        isEffective: "Active"
      },
      effectiveItems: [
        { text: "Active", value: "Active" },
        { text: "Inactive", value: "Inactive" },
        { text: "All", value: "All" }
      ],
      headers: [],
      total: 0,
      options: {
        itemsPerPage: 10,
        sortBy: ["updatedDate"],
        sortDesc: [true]
      },
      deleteConfirm: false,
      deleteConfirmItem: null
    };
  },
  watch: {
    sale: {
      handler() {
        this.getData();
      }
    },
    options: {
      handler() {
        this.getData();
      },
      deep: true
    }
  },
  methods: {
    onSubmit() {
      this.options.page = 0;
      this.getData();
    },
    getData() {
      this.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      let f = Object.assign({}, this.filters);

      if (this.sale) {
        f.sale = { id: this.sale.id };
      }
      if (this.participant && this.participant.id) {
        f.participant = { id: this.participant.id };
      }
      if (this.organization && this.organization.id) {
        f.organization = { id: this.organization.id };
      }

      if (this.search && this.search.trim().length > 0) {
        f.keyword = this.search.trim();
      }

      this.$api
        .post(
          "/api/payouts/search?size=" +
            itemsPerPage +
            "&page=" +
            (page - 1) +
            (sortBy && sortBy.length > 0
              ? "&sort=" + sortBy[0] + "," + ((sortDesc && sortDesc.length > 0) & sortDesc[0] ? "DESC" : "ASC")
              : ""),
          f
        )
        .then(({ data }) => {
          this.loading = false;
          this.items = data.content;
          this.total = data.totalElements;
        })
        .catch(error => {
          this.loading = false;
          console.log(error);
        });
    },
    onClear() {
      this.search = "";
      this.getData();
    },
    onDeleteConfirm(item) {
      this.deleteConfirmItem = item;
      this.deleteConfirm = true;
    },
    onDeleteCancel() {
      this.deleteConfirmItem = null;
      this.deleteConfirm = false;
    },
    onDelete(item) {
      this.errors = {};
      this.loading = true;
      return this.$api
        .delete("/api/payouts/" + item.id + "/delete")
        .then(() => this.getData())
        .catch(error => {
          this.errors = this.$api.getErrorsFromResponse(error);
        })
        .finally(() => {
          this.onDeleteCancel();
          this.loading = false;
        });
    }
  },
  mounted() {
    this.headers = [
      {
        value: "payoutKey",
        text: "Key",
        sortable: true
      },
      {
        value: "promotion.name",
        text: this.$i18n.translate("Promotion"),
        sortable: true
      },
      {
        value: "participant",
        text: this.$i18n.translate("Participant"),
        sortable: true
      },
      {
        value: "organization",
        text: this.$i18n.translate("Organization"),
        sortable: true
      },
      {
        value: "product",
        text: this.$i18n.translate("Product"),
        sortable: false
      },
      {
        value: "payoutAmount",
        text: this.$i18n.translate("Payout"),
        align: "right",
        sortable: true
      },
      {
        value: "bankTransaction",
        text: this.$i18n.translate("Payment"),
        sortable: false
      },
      {
        value: "approval",
        text: "Approval Needed?",
        sortable: true
      },
      {
        value: "updatedDate",
        text: this.$i18n.translate("Last Updated"),
        sortable: true
      },
      {
        value: "actions",
        text: "",
        align: "center",
        sortable: false,
        show: false
      }
    ];
    if (!this.nested) {
      this.$store.dispatch("setBreadcrumb", [
        {
          text: this.$i18n.translate("Dashboard"),
          to: { name: "dashboard" },
          exact: true
        },
        {
          text: this.$i18n.translate("Clients"),
          to: { name: "clients" },
          exact: true
        },
        {
          text: this.selectedClient.name,
          to: {
            name: "client",
            params: { id: this.selectedClient.id },
            exact: true
          }
        },
        {
          text: this.$i18n.translate("Programs"),
          to: { name: "programs" },
          exact: true
        },
        {
          text: this.selectedProgram.name,
          to: {
            name: "program",
            params: { id: this.selectedProgram.id },
            exact: true
          }
        },
        { text: this.$i18n.translate("Payouts") }
      ]);
    }
    // this.getData();
  },
  computed: {
    ...mapGetters(["selectedClient", "selectedProgram"])
  }
};
</script>
