<template>
  <v-container>
    <v-row justify="end" class="mt-1">
      <v-tooltip bottom v-if="isEditing">
        <template v-slot:activator="{ on }">
          <v-btn fab small class="mr-2" v-on="on" @click="onAddDialog()">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>{{ $i18n.translate("Add") }} {{ $i18n.translate("Sale") }} {{ $i18n.translate("Product") }}</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn fab small class="mr-2" v-on="on" @click="onEdit" :disabled="disabled || !isEditing">
            <v-icon v-if="isEditing">mdi-close</v-icon>
            <v-icon v-else>mdi-pencil</v-icon>
          </v-btn>
        </template>
        <span v-if="isEditing">{{ $i18n.translate("Cancel") }}</span>
        <span v-else>{{ $i18n.translate("Edit") }} {{ $i18n.translate("Sale") }} {{ $i18n.translate("Product") }}</span>
      </v-tooltip>
    </v-row>

    <ApiError :errors="errors"></ApiError>

    <v-data-table :headers="headers" :items="items" hide-default-footer disable-pagination>
      <template v-slot:item.amount="{ item }">
        <span v-if="item.amount">
          {{ item.amount | toNumber(2) }}
          {{ sale.currency ? sale.currency.name : "" }}
        </span>
      </template>

      <template v-slot:item.payoutsGenerated="{ item }">
        {{ item.payoutsGenerated === true ? "Yes" : "No" }}
        <span v-if="item.payoutsGenerated"
          ><br />{{ item.payoutsGeneratedDate | formatDateClient("MM/DD/YYYY hh:mm a z", selectedClient) }}</span
        >
      </template>

      <template v-slot:item.paid="{ item }">
        {{ item.paid === true ? "Yes" : "No" }}
        <span v-if="item.paid"
          ><br />{{ item.paidDate | formatDateClient("MM/DD/YYYY hh:mm a z", selectedClient) }}</span
        >
      </template>

      <template v-slot:item.saleProductFields="{ item }">
        <span
          v-if="sale && selectedProgram.saleProductCustomFields && selectedProgram.saleProductCustomFields.length >= 1"
        >
          <b>{{ selectedProgram.saleProductCustomFields[0].name }}</b
          >: {{ item.customFieldValue1 | customField(selectedProgram.saleProductCustomFields[0]) }}<br />
        </span>
        <span
          v-if="sale && selectedProgram.saleProductCustomFields && selectedProgram.saleProductCustomFields.length >= 2"
        >
          <b>{{ selectedProgram.saleProductCustomFields[1].name }}</b
          >: {{ item.customFieldValue2 | customField(selectedProgram.saleProductCustomFields[1]) }}<br />
        </span>
        <span
          v-if="sale && selectedProgram.saleProductCustomFields && selectedProgram.saleProductCustomFields.length >= 3"
        >
          <b>{{ selectedProgram.saleProductCustomFields[2].name }}</b
          >: {{ item.customFieldValue3 | customField(selectedProgram.saleProductCustomFields[2]) }}<br />
        </span>
        <span
          v-if="sale && selectedProgram.saleProductCustomFields && selectedProgram.saleProductCustomFields.length >= 4"
        >
          <b>{{ selectedProgram.saleProductCustomFields[3].name }}</b
          >: {{ item.customFieldValue4 | customField(selectedProgram.saleProductCustomFields[3]) }}<br />
        </span>
        <span
          v-if="sale && selectedProgram.saleProductCustomFields && selectedProgram.saleProductCustomFields.length >= 5"
        >
          <b>{{ selectedProgram.saleProductCustomFields[4].name }}</b
          >: {{ item.customFieldValue5 | customField(selectedProgram.saleProductCustomFields[4]) }}<br />
        </span>
        <span
          v-if="sale && selectedProgram.saleProductCustomFields && selectedProgram.saleProductCustomFields.length >= 6"
        >
          <b>{{ selectedProgram.saleProductCustomFields[5].name }}</b
          >: {{ item.customFieldValue6 | customField(selectedProgram.saleProductCustomFields[5]) }}<br />
        </span>
        <span
          v-if="sale && selectedProgram.saleProductCustomFields && selectedProgram.saleProductCustomFields.length >= 7"
        >
          <b>{{ selectedProgram.saleProductCustomFields[6].name }}</b
          >: {{ item.customFieldValue7 | customField(selectedProgram.saleProductCustomFields[6]) }}<br />
        </span>
        <span
          v-if="sale && selectedProgram.saleProductCustomFields && selectedProgram.saleProductCustomFields.length >= 8"
        >
          <b>{{ selectedProgram.saleProductCustomFields[7].name }}</b
          >: {{ item.customFieldValue8 | customField(selectedProgram.saleProductCustomFields[7]) }}<br />
        </span>
        <span
          v-if="sale && selectedProgram.saleProductCustomFields && selectedProgram.saleProductCustomFields.length >= 9"
        >
          <b>{{ selectedProgram.saleProductCustomFields[8].name }}</b
          >: {{ item.customFieldValue9 | customField(selectedProgram.saleProductCustomFields[8]) }}<br />
        </span>
        <span
          v-if="sale && selectedProgram.saleProductCustomFields && selectedProgram.saleProductCustomFields.length >= 10"
        >
          <b>{{ selectedProgram.saleProductCustomFields[9].name }}</b
          >: {{ item.customFieldValue10 | customField(selectedProgram.saleProductCustomFields[9]) }}<br />
        </span>
      </template>

      <template v-slot:item.productFields="{ item }">
        <span
          v-if="
            sale &&
              selectedProgram.programGroup.productCustomFields &&
              selectedProgram.programGroup.productCustomFields.length >= 1
          "
        >
          <b>{{ selectedProgram.programGroup.productCustomFields[0].name }}</b
          >: {{ item.product.customFieldValue1 | customField(selectedProgram.programGroup.productCustomFields[0])
          }}<br />
        </span>
        <span
          v-if="
            sale &&
              selectedProgram.programGroup.productCustomFields &&
              selectedProgram.programGroup.productCustomFields.length >= 2
          "
        >
          <b>{{ selectedProgram.programGroup.productCustomFields[1].name }}</b
          >: {{ item.product.customFieldValue2 | customField(selectedProgram.programGroup.productCustomFields[1])
          }}<br />
        </span>
        <span
          v-if="
            sale &&
              selectedProgram.programGroup.productCustomFields &&
              selectedProgram.programGroup.productCustomFields.length >= 3
          "
        >
          <b>{{ selectedProgram.programGroup.productCustomFields[2].name }}</b
          >: {{ item.product.customFieldValue3 | customField(selectedProgram.programGroup.productCustomFields[2])
          }}<br />
        </span>
        <span
          v-if="
            sale &&
              selectedProgram.programGroup.productCustomFields &&
              selectedProgram.programGroup.productCustomFields.length >= 4
          "
        >
          <b>{{ selectedProgram.programGroup.productCustomFields[3].name }}</b
          >: {{ item.product.customFieldValue4 | customField(selectedProgram.programGroup.productCustomFields[3])
          }}<br />
        </span>
        <span
          v-if="
            sale &&
              selectedProgram.programGroup.productCustomFields &&
              selectedProgram.programGroup.productCustomFields.length >= 5
          "
        >
          <b>{{ selectedProgram.programGroup.productCustomFields[4].name }}</b
          >: {{ item.product.customFieldValue5 | customField(selectedProgram.programGroup.productCustomFields[4])
          }}<br />
        </span>
        <span
          v-if="
            sale &&
              selectedProgram.programGroup.productCustomFields &&
              selectedProgram.programGroup.productCustomFields.length >= 6
          "
        >
          <b>{{ selectedProgram.programGroup.productCustomFields[5].name }}</b
          >: {{ item.product.customFieldValue6 | customField(selectedProgram.programGroup.productCustomFields[5])
          }}<br />
        </span>
        <span
          v-if="
            sale &&
              selectedProgram.programGroup.productCustomFields &&
              selectedProgram.programGroup.productCustomFields.length >= 7
          "
        >
          <b>{{ selectedProgram.programGroup.productCustomFields[6].name }}</b
          >: {{ item.product.customFieldValue7 | customField(selectedProgram.programGroup.productCustomFields[6])
          }}<br />
        </span>
        <span
          v-if="
            sale &&
              selectedProgram.programGroup.productCustomFields &&
              selectedProgram.programGroup.productCustomFields.length >= 8
          "
        >
          <b>{{ selectedProgram.programGroup.productCustomFields[7].name }}</b
          >: {{ item.product.customFieldValue8 | customField(selectedProgram.programGroup.productCustomFields[7])
          }}<br />
        </span>
        <span
          v-if="
            sale &&
              selectedProgram.programGroup.productCustomFields &&
              selectedProgram.programGroup.productCustomFields.length >= 9
          "
        >
          <b>{{ selectedProgram.programGroup.productCustomFields[8].name }}</b
          >: {{ item.product.customFieldValue9 | customField(selectedProgram.programGroup.productCustomFields[8])
          }}<br />
        </span>
        <span
          v-if="
            sale &&
              selectedProgram.programGroup.productCustomFields &&
              selectedProgram.programGroup.productCustomFields.length >= 10
          "
        >
          <b>{{ selectedProgram.programGroup.productCustomFields[9].name }}</b
          >: {{ item.product.customFieldValue10 | customField(selectedProgram.programGroup.productCustomFields[9])
          }}<br />
        </span>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon class="ml-2" v-if="isEditing" @click="onEditDialog(item)">
          mdi-pencil
        </v-icon>
        <v-icon class="ml-2" v-if="isEditing" @click="onDeleteConfirm(item)">
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>

    <v-dialog v-model="deleteConfirm" max-width="500">
      <v-card>
        <v-card-title>
          Delete this sale product?
        </v-card-title>
        <v-card-text v-if="deleteConfirmItem">
          <b>Product</b>: {{ deleteConfirmItem.product.productKey }}<br />
          <b>Product Name</b>: {{ deleteConfirmItem.product.name }}<br />
        </v-card-text>

        <v-card-actions
          ><v-spacer></v-spacer><v-btn class="primary" @click="onDelete(deleteConfirmItem)">Yes</v-btn
          ><v-btn @click="onDeleteCancel()">No</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="editDialog" v-if="editItem" max-width="500">
      <v-card>
        <v-card-title>
          New sale product
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form :value="valid" @submit.prevent="onAddSaleProduct()" v-model="valid">
              <ProductsField
                label="Product"
                :programGroupKey="selectedProgram.programGroup.programGroupKey"
                v-model="editItem.product"
                :multiple="false"
                class="required"
              />

              <v-text-field :label="$i18n.translate('Serial Number')" v-model="editItem.serialNumber"></v-text-field>

              <v-text-field
                label="Quantity"
                type="number"
                min="1"
                max="9999999"
                v-model="editItem.quantity"
                :rules="rules.quantity"
                class="required"
              ></v-text-field>

              <v-text-field label="Amount" type="number" min="0" max="9999999" v-model="editItem.amount"></v-text-field>

              <CustomField
                v-model="editItem.customFieldValue1"
                v-if="selectedProgram.saleProductCustomFields && selectedProgram.saleProductCustomFields.length >= 1"
                :customField="selectedProgram.saleProductCustomFields[0]"
              />

              <CustomField
                v-model="editItem.customFieldValue2"
                v-if="selectedProgram.saleProductCustomFields && selectedProgram.saleProductCustomFields.length >= 2"
                :customField="selectedProgram.saleProductCustomFields[1]"
              />

              <CustomField
                v-model="editItem.customFieldValue3"
                v-if="selectedProgram.saleProductCustomFields && selectedProgram.saleProductCustomFields.length >= 3"
                :customField="selectedProgram.saleProductCustomFields[2]"
              />

              <CustomField
                v-model="editItem.customFieldValue4"
                v-if="selectedProgram.saleProductCustomFields && selectedProgram.saleProductCustomFields.length >= 4"
                :customField="selectedProgram.saleProductCustomFields[3]"
              />

              <CustomField
                v-model="editItem.customFieldValue5"
                v-if="selectedProgram.saleProductCustomFields && selectedProgram.saleProductCustomFields.length >= 5"
                :customField="selectedProgram.saleProductCustomFields[4]"
              />

              <CustomField
                v-model="editItem.customFieldValue6"
                v-if="selectedProgram.saleProductCustomFields && selectedProgram.saleProductCustomFields.length >= 6"
                :customField="selectedProgram.saleProductCustomFields[5]"
              />

              <CustomField
                v-model="editItem.customFieldValue7"
                v-if="selectedProgram.saleProductCustomFields && selectedProgram.saleProductCustomFields.length >= 7"
                :customField="selectedProgram.saleProductCustomFields[6]"
              />

              <CustomField
                v-model="editItem.customFieldValue8"
                v-if="selectedProgram.saleProductCustomFields && selectedProgram.saleProductCustomFields.length >= 8"
                :customField="selectedProgram.saleProductCustomFields[7]"
              />

              <CustomField
                v-model="editItem.customFieldValue9"
                v-if="selectedProgram.saleProductCustomFields && selectedProgram.saleProductCustomFields.length >= 9"
                :customField="selectedProgram.saleProductCustomFields[8]"
              />

              <CustomField
                v-model="editItem.customFieldValue10"
                v-if="selectedProgram.saleProductCustomFields && selectedProgram.saleProductCustomFields.length >= 10"
                :customField="selectedProgram.saleProductCustomFields[9]"
              />
            </v-form>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn v-if="editItem.id" class="primary" :disabled="!valid" @click="onEdit()">Update</v-btn>
          <v-btn v-else class="primary" :disabled="!valid" @click="onAdd()">Add</v-btn>
          <v-btn @click="onEditDialogCancel()">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import ApiError from "../display/ApiError.vue";
import CustomField from "../fields/CustomField.vue";
import ProductsField from "../fields/ProductsField.vue";

export default {
  name: "SaleProductTable",
  props: {
    value: [Object, Array],
    disabled: Boolean,
    isEditing: {
      type: Boolean,
      default: false
    },
    sale: Object,
    saleType: Object
  },
  components: { ApiError, ProductsField, CustomField },
  data: () => ({
    loading: false,

    selectedSaleType: null,

    deleteConfirm: false,
    deleteConfirmItem: null,

    editDialog: false,
    editItem: null,

    rules: {
      serialNumber: [v => !!v || "Required"],
      quantity: [v => !!v || "Quantity is required"]
    },

    valid: true,

    errors: {},
    items: [],
    headers: [
      {
        value: "product.productKey",
        text: "Product",
        sortable: true
      },
      {
        value: "product.name",
        text: "Name",
        sortable: false
      },
      {
        value: "serialNumber",
        text: "Serial #",
        sortable: true
      },
      {
        value: "quantity",
        text: "Qty",
        align: "center",
        sortable: true
      },
      {
        value: "amount",
        text: "Amount",
        align: "right",
        sortable: true
      },
      {
        value: "saleProductFields",
        text: "Sale Product Fields",
        sortable: false
      },
      {
        value: "productFields",
        text: "Product Fields",
        sortable: false
      },
      {
        value: "payoutsGenerated",
        text: "Payouts Generated?",
        sortable: false
      },
      {
        value: "paid",
        text: "Paid?",
        sortable: false
      },
      {
        value: "actions",
        text: "",
        align: "center",
        sortable: false,
        show: false
      }
    ]
  }),
  created() {
    if (this.saleType) {
      this.selectedSaleType = this.saleType;
    } else if (this.sale.saleType) {
      this.selectedSaleType = this.sale.saleType;
    }
  },
  mounted() {
    this.fetchSaleProducts();
  },
  methods: {
    fetchSaleProducts() {
      this.loading = true;
      this.$api
        .get("/api/saleProducts/bySaleId/" + this.sale.id + "?size=100")
        .then(({ data }) => {
          this.items = data.content;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onDeleteConfirm(item) {
      this.deleteConfirmItem = item;
      this.deleteConfirm = true;
    },
    onDeleteCancel() {
      this.deleteConfirmItem = null;
      this.deleteConfirm = false;
    },
    onDelete(item) {
      this.errors = {};
      this.loading = true;
      return this.$api
        .delete("/api/saleProducts/" + item.id + "/delete")
        .then(response => {
          if (response) {
            this.fetchSaleProducts();
          }
        })
        .catch(error => {
          this.errors = this.$api.getErrorsFromResponse(error);
        })
        .finally(() => {
          this.onDeleteCancel();
          this.loading = false;
        });
    },
    onEditDialog(item) {
      this.editDialog = true;
      this.editItem = Object.assign({}, item);
    },
    onEditDialogCancel() {
      this.editDialog = false;
      this.editItem = null;
    },
    onAddDialog() {
      this.editItem = {
        quantity: 1
      };
      this.editDialog = true;
    },
    onEdit() {
      this.loading = true;
      this.errors = {};

      // only send IDs
      delete this.editItem.currency;
      this.editItem.sale = { id: this.sale.id };
      this.editItem.product = { id: this.editItem.product.id };

      this.$api
        .post("/api/saleProducts/" + this.editItem.id + "/update", this.editItem)
        .then(() => {
          this.editDialog = false;
          this.editItem = false;
          this.fetchSaleProducts();
        })
        .catch(error => {
          this.editDialog = false;
          this.editItem = false;
          this.errors = this.$api.getErrorsFromResponse(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onAdd() {
      this.loading = true;
      this.errors = {};

      // only send IDs
      this.editItem.sale = { id: this.sale.id };
      this.editItem.product = { id: this.editItem.product.id };

      this.$api
        .post("/api/saleProducts/create", this.editItem)
        .then(() => {
          this.editDialog = false;
          this.editItem = false;
          this.fetchSaleProducts();
        })
        .catch(error => {
          this.editDialog = false;
          this.editItem = false;
          this.errors = this.$api.getErrorsFromResponse(error);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  computed: {
    ...mapGetters(["selectedProgram", "selectedClient"])
  }
};
</script>
